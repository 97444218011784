import authService from "./auth.service";
import userService from "./user.service";
import fileService from "./file.service";
import orderService from "./order.service";

export default {
  // fileService
  fileAdd(params) {
    return fileService.fileAdd(params);
  },
  // authService
  authLogin(params) {
    return authService.login(params);
  },
  authLogout() {
    return authService.logout();
  },
  authUser() {
    return authService.user();
  },
  authRegister(params) {
    return authService.register(params);
  },
  authRecover(params) {
    return authService.recover(params);
  },
  // userService
  userModify(params) {
    return userService.modify(params);
  },
  userFindOne(id) {
    return userService.findOne(id);
  },
  // orderService
  productFindAll(params) {
    return orderService.productFindAll(params);
  },
  orderAdd(params) {
    return orderService.orderAdd(params);
  },
  orderFindAll(params) {
    return orderService.orderFindAll(params);
  },
  orderUserFindAll(id, params) {
    return orderService.orderUserFindAll(id, params);
  },
  orderFindOne(id) {
    return orderService.orderFindOne(id);
  },
  orderRefund(params, id) {
    return orderService.orderRefund(params, id);
  },
  // reference
  referenceAdd(params) {
    return orderService.referenceAdd(params);
  },
};
