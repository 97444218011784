// var CryptoJS = require("crypto-js");

export const Crypto = {
  /**
   * salt=2EB22F41F4CE294C
   * key=86FCC31A25BFCFB294D8453062D15F22
   * iv =057264DB014AB3F7D79E56711C6B2F94
   */
  cryptoAesEncrypt(value) {
    /*
    var aesKey = CryptoJS.enc.Utf8.parse("86FCC31A25BFCFB294D8453062D15F22");
    var iv = CryptoJS.enc.Utf8.parse("6248aab5c4a1e305/166248aab5c4a1e305");
    var encrypted = CryptoJS.AES.encrypt(String(value), aesKey, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString();
    return encrypted;
    */
    return value;
  },
  cryptoAesDecrypt(value) {
    /*
    var aesKey = CryptoJS.enc.Utf8.parse("86FCC31A25BFCFB294D8453062D15F22");
    var iv = CryptoJS.enc.Utf8.parse("6248aab5c4a1e305/166248aab5c4a1e305");
    var encrypted = CryptoJS.AES.decrypt(String(value), aesKey, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }).toString(CryptoJS.enc.Utf8);
    return encrypted;
    */
    return value;
  },
};

export default Crypto;
