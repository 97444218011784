import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// 파일 업로드
const fileAdd = (params) => {
  return http.post(SERVICE_API_URL + `/file`, params, {
    headers: authHeader(),
  });
};

export default {
  fileAdd,
};
