import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const login = (params) => {
  return http.post(SERVICE_API_URL + "/auth/login", params, {
    headers: authHeader(),
  });
};

const logout = () => {
  return http.post(SERVICE_API_URL + "/auth/logout", {
    headers: authHeader(),
  });
};

const user = () => {
  return http.get(SERVICE_API_URL + "/auth/user", {
    headers: authHeader(),
  });
};

const register = (params) => {
  return http.post(SERVICE_API_URL + "/auth/register", params, {
    headers: authHeader(),
  });
};

const recover = (params) => {
  return http.post(SERVICE_API_URL + "/auth/recover", params, {
    headers: authHeader(),
  });
};

export default {
  login,
  logout,
  user,
  register,
  recover,
};
