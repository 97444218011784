<template>
  <component
    :is="tag"
    :type="tag === 'button' ? nativeType : ''"
    @click="handleClick"
    class="btn"
    :class="classes"
  >
    <span class="btn-inner--text">
      <slot>
        {{ text }}
      </slot>
    </span>
  </component>
</template>

<script>
export default {
  name: "BaseButton",
  components: {
    /**
     * components
     */
  },
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button tag (default -> button)",
    },
    type: {
      type: String,
      default: "default",
      description: "Button type (e,g primary, danger etc)",
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g submit,button etc)",
    },
    text: {
      type: String,
      default: "",
      description: "Button text in case not provided via default slot",
    },
    block: {
      type: Boolean,
      default: false,
      description: "Whether button is of block type",
    },
  },
  data() {
    /**
     * data
     */
    return {};
  },
  created() {
    /**
     * created
     */
  },
  mounted() {
    /**
     * mounted
     */
  },
  computed: {
    /**
     * computed
     */
    classes() {
      let btnClasses = [
        { "btn-block": this.block },
        { [`text-${this.textColor}`]: this.textColor },
        this.type && !this.outline ? `btn-${this.type}` : "",
      ];
      // if (this.size) {
      //   btnClasses.push(`btn-${this.size}`);
      // }
      return btnClasses;
    },
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    /**
     * methods
     */
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
