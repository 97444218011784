var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{ active: _vm.isActive }},[_c('div',{staticClass:"header_contents"},[_c('div',{staticClass:"header_left"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[(_vm.$route.name == 'Home')?_c('img',{staticClass:"logo_home",attrs:{"src":require("@/assets/img/logo-white.png"),"alt":"logo"}}):_vm._e(),(_vm.$route.name == 'Home')?_c('img',{staticClass:"logo_home",attrs:{"src":require("@/assets/img/logo.png"),"alt":"logo"}}):_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo.png"),"alt":"logo"}})]),_c('ul',{staticClass:"header_btns"},[_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('p',{class:{ active: _vm.$route.name == 'Home' }},[_vm._v("스탬프 팝 소개")])])],1),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'EntryCost' }}},[_c('p',{class:{
                active: _vm.$route.name == 'EntryCost',
                black_active:
                  _vm.$route.name != 'Home' && _vm.$route.name == 'EntryCost',
              }},[_vm._v(" 입점 비용 ")])])],1),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'EntryProcedure' }}},[_c('p',{class:{
                active: _vm.$route.name == 'EntryProcedure',
                black_active:
                  _vm.$route.name != 'Home' && _vm.$route.name == 'EntryProcedure',
              }},[_vm._v(" 입점 절차 ")])])],1)])],1),(_vm.isAuthenticated)?_c('ul',{staticClass:"header_btns",class:{ active: _vm.isAuthenticated }},[_c('li',{staticClass:"mobile_menu",class:{ active: _vm.$route.name != 'Home' },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.isActiveBtn = !_vm.isActiveBtn}}},[_vm._m(0),_c('ul',{staticClass:"mobile_header_btns",class:{ active: _vm.isActiveBtn }},[_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('p',{class:{ black_active: _vm.$route.name == 'Home' }},[_vm._v(" 스탬프 팝 소개 ")])])],1),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'EntryCost' }}},[_c('p',{class:{
                  active: _vm.$route.name == 'EntryCost',
                  black_active:
                    _vm.$route.name != 'Home' && _vm.$route.name == 'EntryCost',
                }},[_vm._v(" 입점 비용 ")])])],1),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'EntryProcedure' }}},[_c('p',{class:{
                  active: _vm.$route.name == 'EntryProcedure',
                  black_active:
                    _vm.$route.name != 'Home' && _vm.$route.name == 'EntryProcedure',
                }},[_vm._v(" 입점 절차 ")])])],1)])]),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'Mypage' }}},[_c('img',{attrs:{"src":require("@/assets/img/my.png"),"alt":"마이스탬프"}}),_c('p',[_vm._v("MY스탬프 팝")])])],1),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/img/logout.png"),"alt":"로그아웃"}}),_c('p',[_vm._v("로그아웃")])])]),_c('li',[_c('button',{attrs:{"type":"button"},on:{"click":_vm.orderProduct}},[_vm._v(" 스탬프 팝 입점하기 "),_vm._m(1)])])]):_c('ul',{staticClass:"header_btns"},[_c('li',{staticClass:"mobile_menu",class:{ active: _vm.$route.name != 'Home' },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.isActiveBtn = !_vm.isActiveBtn}}},[_vm._m(2),_c('ul',{staticClass:"mobile_header_btns",class:{ active: _vm.isActiveBtn }},[_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('p',{class:{ black_active: _vm.$route.name == 'Home' }},[_vm._v(" 스탬프 팝 소개 ")])])],1),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'EntryCost' }}},[_c('p',{class:{
                  active: _vm.$route.name == 'EntryCost',
                  black_active:
                    _vm.$route.name != 'Home' && _vm.$route.name == 'EntryCost',
                }},[_vm._v(" 입점 비용 ")])])],1),_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'EntryProcedure' }}},[_c('p',{class:{
                  active: _vm.$route.name == 'EntryProcedure',
                  black_active:
                    _vm.$route.name != 'Home' && _vm.$route.name == 'EntryProcedure',
                }},[_vm._v(" 입점 절차 ")])])],1)])]),(_vm.$route.name != 'Login')?_c('li',{class:{ active: _vm.$route.name != 'Home' }},[_c('a',{on:{"click":_vm.login}},[_c('img',{attrs:{"src":require("@/assets/img/login.png"),"alt":"로그인"}}),_c('p',[_vm._v("로그인")])])]):_vm._e(),(_vm.$route.name != 'Register')?_c('li',{staticClass:"register_link",class:{ active: _vm.$route.name != 'Home' }},[_c('router-link',{attrs:{"to":{ name: 'Register' }}},[_c('p',[_vm._v("회원가입")])])],1):_vm._e(),_c('li',[_c('button',{attrs:{"type":"button"},on:{"click":_vm.orderProduct}},[_vm._v(" 스탬프 팝 입점하기 "),_vm._m(3)])])])]),(
      _vm.$route.name == 'MypageAccount' ||
      _vm.$route.name == 'MypagePassword' ||
      _vm.$route.name == 'MypageFestivalOrder' ||
      _vm.$route.name == 'MypageFestivalOrderDetail' ||
      _vm.$route.name == 'MypageFestivalReference'
    )?_c('nav',[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'MypageAccount' }}},[_vm._v("회원정보수정")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'MypagePassword' }}},[_vm._v("비밀번호 변경")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'MypageFestivalOrder' }}},[_vm._v("결제내역/진행현황")]),_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'MypageFestivalReference' }}},[_vm._v("축제 자료 등록")]),_c('a',{staticClass:"nav-link",attrs:{"href":"https://nextinnovation.notion.site/02bf147159504bdda53d6752f0453f09","target":"_blank"}},[_vm._v("공지사항")])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',[_c('img',{attrs:{"src":require("@/assets/img/burger.png"),"alt":"메뉴"}}),_c('p',[_vm._v("더보기")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/rectangle-31.png"),"alt":"스탬프 팝 입점하기"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',[_c('img',{attrs:{"src":require("@/assets/img/burger.png"),"alt":"메뉴"}}),_c('p',[_vm._v("더보기")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/rectangle-31.png"),"alt":"스탬프 팝 입점하기"}})])}]

export { render, staticRenderFns }