import Vue from "vue";
import Vuex from "vuex";

import i18n from "@/i18n";
import moment from "moment";

/**
 * services
 */
import services from "@/services";

/**
 * storage
 */
import storage from "@/common/storage";
import {
  ID_TOKEN_KEY,
  ID_LANGUAGE_KEY,
  ID_SAVE_LOGINID_KEY,
  ID_USER_KEY,
} from "@/constants";

Vue.use(Vuex);

// Crypto
import Crypto from "@/common/crypto";

export default new Vuex.Store({
  state: {
    token: {
      accessToken: storage.get(ID_TOKEN_KEY),
    },
    user: storage.get(ID_USER_KEY),
    saveLoginid: storage.get(ID_SAVE_LOGINID_KEY),
    language: storage.get(ID_LANGUAGE_KEY)
      ? storage.get(ID_LANGUAGE_KEY)
      : "ko",
    isAuthenticated: !!storage.get(ID_TOKEN_KEY),
    basket: {
      order: {
        id: null,
      },
      product: {
        id: null,
      },
    }, // 장바구니
    regInfo: {
      loginid: null,
      pass: null,
      corporationName: null,
      corporationNumber: null,
      name: null,
      phone: null,
      email: null,
      tel: null,
    },
  },
  getters: {
    getUser(state) {
      console.log(state);
      return state.user;
    },
    getAccessToken: function (state) {
      return state.token.accessToken;
    },
    isAuthenticated: function (state) {
      return state.isAuthenticated;
    },
    getLanguage(state) {
      return state.language;
    },
    getSaveLoginid(state) {
      return state.saveLoginid;
    },
    getBasket: function (state) {
      console.log("getBasket:");
      return state.basket;
    },
    getRegInfo(state) {
      return state.regInfo;
    },
  },
  mutations: {
    logout: function (state) {
      state.token.accessToken = "";
      state.isAuthenticated = false;
      storage.remove(ID_TOKEN_KEY);
      storage.remove(ID_USER_KEY);
    },
    user: function (state, payload = {}) {
      state.user = payload.user;
      console.log(state.user);
      storage.save(ID_USER_KEY, payload.user);
    },
    login: function (state, payload = {}) {
      state.token.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      storage.save(ID_TOKEN_KEY, payload.accessToken);
    },
    language: function (state, data) {
      state.language = data.language;
      storage.save(ID_LANGUAGE_KEY, data.language);
      i18n.locale = data.language;
      moment.locale(data.language);
    },
    saveLoginid: function (state, data) {
      console.log(data);
      state.saveLoginid = data.loginid;
      if (data.loginid) {
        storage.save(ID_SAVE_LOGINID_KEY, data.loginid);
      } else {
        storage.remove(ID_SAVE_LOGINID_KEY);
      }
    },
    basket: function (state, payload = {}) {
      console.log("product");
      console.log(payload);
      state.basket.order.id = payload.order.id;
      state.basket.product.id = payload.product.id;
    },
    regInfo: function (state, payload) {
      state.regInfo.loginid = payload.loginid;
      state.regInfo.pass = payload.pass;
      state.regInfo.corporationName = payload.corporationName;
      state.regInfo.corporationNumber = payload.corporationNumber;
      state.regInfo.name = payload.name;
      state.regInfo.phone = payload.phone;
      state.regInfo.email = payload.email;
      state.regInfo.tel = payload.tel;
    },
  },
  actions: {
    logout: function (context) {
      return new Promise((resolve, reject) => {
        services.authLogout().then(
          (response) => {
            const { data } = response;
            if (response.status == 204) {
              console.log(context);
              console.log(data);
              context.commit("logout");
            }

            resolve(response);
          },
          (error) => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    login: function (context, payload) {
      const params = {};

      params.loginid = Crypto.cryptoAesEncrypt(payload.loginid);
      params.pass = Crypto.cryptoAesEncrypt(payload.pass);

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authLogin(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
              context.commit("login", {
                accessToken: data.accessToken,
              });
              if (payload.saveLoginid) {
                context.commit("saveLoginid", {
                  loginid: payload.loginid,
                });
              } else {
                context.commit("saveLoginid", {
                  loginid: "",
                });
              }
            }

            services.authUser().then(
              (response) => {
                const { data } = response;
                if (response.status == 200) {
                  context.commit("user", {
                    user: data,
                  });
                }

                resolve(response);
              },
              (error) => {
                // status 404
                const { data } = error.response;
                reject(data.message);
              }
            );
          },
          (error) => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    account: function (context, payload) {
      const params = {};

      params.name = Crypto.cryptoAesEncrypt(payload.name);
      params.tel = Crypto.cryptoAesEncrypt(payload.tel);
      params.email = Crypto.cryptoAesEncrypt(payload.email);

      return new Promise((resolve, reject) => {
        services.authLogin(params);

        services.userModify(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
            }

            services.authUser().then(
              (response) => {
                const { data } = response;
                if (response.status == 200) {
                  context.commit("user", {
                    user: data,
                  });
                }

                resolve(response);
              },
              (error) => {
                // status 404
                const { data } = error.response;
                reject(data.message);
              }
            );
          },
          (error) => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    register: function (context, payload) {
      const params = {};

      params.loginid = Crypto.cryptoAesEncrypt(payload.loginid);
      params.pass = Crypto.cryptoAesEncrypt(payload.pass);
      params.corporationName = Crypto.cryptoAesEncrypt(payload.corporationName);
      params.corporationNumber = Crypto.cryptoAesEncrypt(
        payload.corporationNumber
      );
      params.name = Crypto.cryptoAesEncrypt(payload.name);
      params.tel = Crypto.cryptoAesEncrypt(payload.tel);
      params.email = Crypto.cryptoAesEncrypt(payload.email);
      params.termsAgree = payload.termsAgree;
      params.privacyAgree = payload.privacyAgree;
      params.marketingAgree = payload.marketingAgree;
      console.log(params);
      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authRegister(params).then(
          (response) => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
              context.commit("login", {
                accessToken: data.accessToken,
              });
            }

            services.authUser().then(
              (response) => {
                const { data } = response;
                if (response.status == 200) {
                  context.commit("user", {
                    user: data,
                  });
                }

                resolve(response);
              },
              (error) => {
                // status 404
                const { data } = error.response;
                reject(data.message);
              }
            );
          },
          (error) => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      });
    },
    language({ commit }, { language }) {
      commit("language", {
        language: language,
      });
    },
  },
});
