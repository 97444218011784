<template>
  <base-modal :mask="false">
    <h3 slot="header">{{ title }}</h3>
    <div slot="body" v-html="content">
      <!-- {{ content }} -->
    </div>
    <div slot="footer">
      <template v-if="type == 'confirm'">
        <base-button
          type="modal"
          @click.prevent.stop="$emit('close', { confirm: true })"
          >{{ trueText }}</base-button
        >
        <base-button
          type="modal"
          @click.prevent.stop="$emit('close', { confirm: false })"
          >{{ falseText }}</base-button
        >
      </template>
      <template v-if="type == 'confirm2'">
        <base-button
          type="modal2"
          @click.prevent.stop="$emit('close', { confirm: true })"
          >{{ trueText }}</base-button
        >
        <base-button
          type="modal"
          class="no_change"
          @click.prevent.stop="$emit('close', { confirm: false })"
          >{{ falseText }}</base-button
        >
      </template>
      <template v-else>
        <base-button
          type="modal"
          @click.prevent.stop="$emit('close', { confirm: true })"
          >확인</base-button
        >
      </template>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";

export default {
  components: {
    BaseModal,
  },
  props: {
    title: {
      type: String,
      default: "알림",
      description: "title (default -> 알림)",
    },
    content: {
      type: String,
      default: "-",
      description: "content (default -> -)",
    },
    type: {
      type: String,
      default: "alert",
      description: "type (default -> alert)",
    },
    trueText: {
      type: String,
      default: "확인",
      description: "text (default -> 확인)",
    },
    falseText: {
      type: String,
      default: "취소",
      description: "text (default -> 취소)",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
