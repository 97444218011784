<template>
  <header :class="{ active: isActive }">
    <div class="header_contents">
      <div class="header_left">
        <router-link :to="{ name: 'Home' }">
          <img
            v-if="$route.name == 'Home'"
            src="@/assets/img/logo-white.png"
            alt="logo"
            class="logo_home"
          />
          <img
            v-if="$route.name == 'Home'"
            src="@/assets/img/logo.png"
            alt="logo"
            class="logo_home"
          />
          <img v-else src="@/assets/img/logo.png" alt="logo" class="logo" />
        </router-link>
        <ul class="header_btns">
          <li :class="{ active: $route.name != 'Home' }">
            <router-link :to="{ name: 'Home' }">
              <p :class="{ active: $route.name == 'Home' }">스탬프 팝 소개</p>
            </router-link>
          </li>
          <li :class="{ active: $route.name != 'Home' }">
            <router-link :to="{ name: 'EntryCost' }">
              <p
                :class="{
                  active: $route.name == 'EntryCost',
                  black_active:
                    $route.name != 'Home' && $route.name == 'EntryCost',
                }"
              >
                입점 비용
              </p>
            </router-link>
          </li>
          <li :class="{ active: $route.name != 'Home' }">
            <router-link :to="{ name: 'EntryProcedure' }">
              <p
                :class="{
                  active: $route.name == 'EntryProcedure',
                  black_active:
                    $route.name != 'Home' && $route.name == 'EntryProcedure',
                }"
              >
                입점 절차
              </p>
            </router-link>
          </li>
        </ul>
      </div>
      <ul
        v-if="isAuthenticated"
        class="header_btns"
        :class="{ active: isAuthenticated }"
      >
        <li
          class="mobile_menu"
          :class="{ active: $route.name != 'Home' }"
          @click.prevent.stop="isActiveBtn = !isActiveBtn"
        >
          <a>
            <img src="@/assets/img/burger.png" alt="메뉴" />
            <p>더보기</p>
          </a>
          <ul class="mobile_header_btns" :class="{ active: isActiveBtn }">
            <li :class="{ active: $route.name != 'Home' }">
              <router-link :to="{ name: 'Home' }">
                <p :class="{ black_active: $route.name == 'Home' }">
                  스탬프 팝 소개
                </p>
              </router-link>
            </li>
            <li :class="{ active: $route.name != 'Home' }">
              <router-link :to="{ name: 'EntryCost' }">
                <p
                  :class="{
                    active: $route.name == 'EntryCost',
                    black_active:
                      $route.name != 'Home' && $route.name == 'EntryCost',
                  }"
                >
                  입점 비용
                </p>
              </router-link>
            </li>
            <li :class="{ active: $route.name != 'Home' }">
              <router-link :to="{ name: 'EntryProcedure' }">
                <p
                  :class="{
                    active: $route.name == 'EntryProcedure',
                    black_active:
                      $route.name != 'Home' && $route.name == 'EntryProcedure',
                  }"
                >
                  입점 절차
                </p>
              </router-link>
            </li>
          </ul>
        </li>
        <li :class="{ active: $route.name != 'Home' }">
          <router-link :to="{ name: 'Mypage' }">
            <img src="@/assets/img/my.png" alt="마이스탬프" />
            <p>MY스탬프 팝</p>
          </router-link>
        </li>
        <li :class="{ active: $route.name != 'Home' }">
          <a href="#" @click.prevent.stop="logout">
            <img src="@/assets/img/logout.png" alt="로그아웃" />
            <p>로그아웃</p>
          </a>
        </li>
        <li>
          <button type="button" @click="orderProduct">
            스탬프 팝 입점하기
            <div>
              <img
                src="@/assets/img/rectangle-31.png"
                alt="스탬프 팝 입점하기"
              />
            </div>
          </button>
        </li>
      </ul>
      <ul v-else class="header_btns">
        <li
          class="mobile_menu"
          :class="{ active: $route.name != 'Home' }"
          @click.prevent.stop="isActiveBtn = !isActiveBtn"
        >
          <a>
            <img src="@/assets/img/burger.png" alt="메뉴" />
            <p>더보기</p>
          </a>
          <ul class="mobile_header_btns" :class="{ active: isActiveBtn }">
            <li :class="{ active: $route.name != 'Home' }">
              <router-link :to="{ name: 'Home' }">
                <p :class="{ black_active: $route.name == 'Home' }">
                  스탬프 팝 소개
                </p>
              </router-link>
            </li>
            <li :class="{ active: $route.name != 'Home' }">
              <router-link :to="{ name: 'EntryCost' }">
                <p
                  :class="{
                    active: $route.name == 'EntryCost',
                    black_active:
                      $route.name != 'Home' && $route.name == 'EntryCost',
                  }"
                >
                  입점 비용
                </p>
              </router-link>
            </li>
            <li :class="{ active: $route.name != 'Home' }">
              <router-link :to="{ name: 'EntryProcedure' }">
                <p
                  :class="{
                    active: $route.name == 'EntryProcedure',
                    black_active:
                      $route.name != 'Home' && $route.name == 'EntryProcedure',
                  }"
                >
                  입점 절차
                </p>
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="$route.name != 'Login'"
          :class="{ active: $route.name != 'Home' }"
        >
          <a @click="login">
            <img src="@/assets/img/login.png" alt="로그인" />
            <p>로그인</p>
          </a>
        </li>
        <li
          v-if="$route.name != 'Register'"
          class="register_link"
          :class="{ active: $route.name != 'Home' }"
        >
          <router-link :to="{ name: 'Register' }">
            <p>회원가입</p>
          </router-link>
        </li>
        <li>
          <button type="button" @click="orderProduct">
            스탬프 팝 입점하기
            <div>
              <img
                src="@/assets/img/rectangle-31.png"
                alt="스탬프 팝 입점하기"
              />
            </div>
          </button>
        </li>
      </ul>
    </div>
    <nav
      v-if="
        $route.name == 'MypageAccount' ||
        $route.name == 'MypagePassword' ||
        $route.name == 'MypageFestivalOrder' ||
        $route.name == 'MypageFestivalOrderDetail' ||
        $route.name == 'MypageFestivalReference'
      "
    >
      <router-link class="nav-link" :to="{ name: 'MypageAccount' }"
        >회원정보수정</router-link
      >
      <router-link class="nav-link" :to="{ name: 'MypagePassword' }"
        >비밀번호 변경</router-link
      >
      <router-link class="nav-link" :to="{ name: 'MypageFestivalOrder' }"
        >결제내역/진행현황</router-link
      >
      <router-link class="nav-link" :to="{ name: 'MypageFestivalReference' }"
        >축제 자료 등록</router-link
      >
      <a
        href="https://nextinnovation.notion.site/02bf147159504bdda53d6752f0453f09"
        target="_blank"
        class="nav-link"
        >공지사항</a
      >
    </nav>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/components/Dialog.vue";
export default {
  name: "Header",
  data() {
    return {
      waitRequest: false,
      isActive: false,
      window: {
        width: 0,
        top: "",
      },
      textDecoration: {
        textDecoration: "underline",
      },
      isActiveBtn: false,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getRegInfo"]),
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    login() {
      /*
      console.log(this.getRegInfo);
      if (
        this.getRegInfo.loginid != null ||
        this.getRegInfo.pass != null ||
        this.getRegInfo.corporationName != null ||
        this.getRegInfo.corporationNumber != null ||
        this.getRegInfo.name != null ||
        this.getRegInfo.phone != null ||
        this.getRegInfo.email != null
      ) {
        this.$modal.show(
          Dialog,
          {
            type: "confirm",
            title: "알림",
            content: `회원가입 하지 않고 로그인 페이지로 이동 하시겠습니까?`,
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-close": (response) => {
              if (response.params.confirm) {
                this.$router.push({ name: "Login" });
              }
            },
          }
        );
      } else {
        this.$router.push({ name: "Login" });
      }
      */
      this.$router.push({ name: "Login" });
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
    onScroll() {
      this.window.top = window.top.scrollY;
      if (this.window.top > 1) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
    orderProduct() {
      if (this.isAuthenticated) {
        this.$router.push({ name: "FestivalOrderProduct" });
      } else {
        this.$modal.show(
          Dialog,
          {
            type: "confirm2",
            title: "알림",
            content:
              "스탬프 팝 입점 신청을 위해 로그인해주세요.<br /> 아직 회원이 아닌 경우 회원가입을 해주세요.",
            trueText: "회원가입하기",
            falseText: "로그인하기",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": (response) => {
              if (response.params.confirm) {
                this.$router.push({ name: "Register" });
              } else {
                this.$router.push({ name: "Login" });
              }
            },
          }
        );
      }
    },
    logout() {
      if (!this.waitRequest) {
        this.waitRequest = true;
        this.$store
          .dispatch("logout")
          .then(() => {
            this.waitRequest = false;
            this.$router.push({
              name: "Home",
            });
          })
          .catch((message) => {
            this.waitRequest = false;
            this.$modal.show(
              Dialog,
              {
                title: "알림",
                content: message,
              },
              {
                adaptive: true,
                width: "90%",
                maxWidth: 600,
                height: "auto",
              },
              {
                "before-open": () => {},
                "before-close": () => {},
              }
            );
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/desktop.scss";
</style>
