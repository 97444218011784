// store
import store from "../../store";

export default function authHeader() {
  const accessToken = store.getters["getAccessToken"];

  if (accessToken) {
    return {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": "Bearer " + accessToken,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
}
