import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

// product
const productFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/shop/product`, {
    params: params,
    headers: authHeader(),
  });
};
// order
const orderAdd = (params) => {
  return http.post(SERVICE_API_URL + `/shop/order`, params, {
    headers: authHeader(),
  });
};
const orderFindAll = (params) => {
  return http.get(SERVICE_API_URL + `/shop/order`, {
    params: params,
    headers: authHeader(),
  });
};
const orderUserFindAll = (id, params) => {
  return http.get(SERVICE_API_URL + `/shop/order/user/${id}`, {
    params: params,
    headers: authHeader(),
  });
};
const orderFindOne = (id) => {
  return http.get(SERVICE_API_URL + `/shop/order/${id}`, {
    params: id,
    headers: authHeader(),
  });
};
const orderRefund = (params, id) => {
  return http.post(SERVICE_API_URL + `/shop/order/refund/${id}`, params, {
    headers: authHeader(),
  });
};
// reference
const referenceAdd = (params) => {
  return http.post(SERVICE_API_URL + `/shop/order/reference`, params, {
    headers: authHeader(),
  });
};

export default {
  productFindAll,
  orderAdd,
  orderFindAll,
  orderUserFindAll,
  orderFindOne,
  referenceAdd,
  orderRefund,
};
