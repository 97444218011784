import http from "@/common/http";
import authHeader from "./header";
import { SERVICE_API_URL } from "@/common/config";

const findOne = (id) => {
  return http.get(SERVICE_API_URL + `/user/${id}`, {
    params: id,
    headers: authHeader(),
  });
};
const modify = (params) => {
  return http.patch(SERVICE_API_URL + `/user`, params, {
    headers: authHeader(),
  });
};

export default {
  modify,
  findOne,
};
