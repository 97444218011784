const ID_LANGUAGE_KEY = "id_language";

export const getLanguage = () => {
  let language = window.localStorage.getItem(ID_LANGUAGE_KEY);
  if (!language) {
    if (window.navigator.languages) {
      language = window.navigator.languages[0];
    } else {
      language = window.navigator.userLanguage || window.navigator.language;
    }
  }

  if (language == "en-US") {
    language = "en";
  }
  if (language == "ko-KR") {
    language = "ko";
  }

  return language;
};

export const saveLanguage = (language) => {
  window.localStorage.setItem(ID_LANGUAGE_KEY, language);
};

export const destroyLanguage = () => {
  window.localStorage.removeItem(ID_LANGUAGE_KEY);
};

export default {
  getLanguage,
  saveLanguage,
  destroyLanguage,
};
