<template>
  <footer>
    <div class="footer_wrapper">
      <section>
        <div class="chatbot" @click.prevent.stop="onChannelIO">
          <img src="@/assets/img/chatbot.png" alt="챗봇" />
        </div>
        <div class="terms">
          <a
            href="https://nextinnovation.notion.site/0afc96309d974fe9bb270d3520383df2"
            target="_blank"
            >이용약관</a
          >
          <a
            href="https://nextinnovation.notion.site/d3fd1690607f4015992a1678d03ebf43"
            target="_blank"
            >개인정보처리방침</a
          >
          <a
            href="https://nextinnovation.notion.site/3-1488fd5700974609b8e8c721859f85cf"
            target="_blank"
            >개인정보 제3자 제공 동의</a
          >
        </div>
        <div class="info">
          <div>
            <p>(주)넥스트이노베이션</p>
            <span>|</span>
            <p>서울시 광진구 천호대로 572 범천타워 8층</p>
            <span>|</span>
            <p>대표번호 : 02-6337-0310</p>
          </div>
          <div>
            <p>통신판매업신고번호 : 제2021-서울광진-0299호</p>
            <span>|</span>
            <p>사업자등록번호 : 107-87-87880</p>
            <span>|</span>
            <p>개인정보취급책임자 : 전병훈</p>
          </div>
          <div @click="sendEmail">
            <p>이메일 문의 : festival@nextinnovation.kr</p>
          </div>
        </div>
        <p class="copyright">
          Copyright © 2021 nextinnovation. All Rights Reserved.
        </p>
      </section>
      <section>
        <img class="footer_logo" src="@/assets/img/logo.png" alt="로고" />
      </section>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    sendEmail() {
      window.open("mailto:festival@nextinnovation.kr");
    },
    onChannelIO() {
      document.getElementById("show_chatbot").click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/desktop.scss";
</style>
