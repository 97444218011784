import Vue from "vue";
import VueRouter from "vue-router";

// layout
import AuthLayout from "../layout/AuthLayout.vue";
import BasicLayout from "../layout/BasicLayout.vue";

// store
import store from "../store";

let title = "스탬프팝";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: BasicLayout,
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          title: title + "홈",
          breadcrumb: [{ name: "", link: "" }],
        },
        component: () => import("../views/Home/index.vue"),
      },
      {
        path: "/register/complete",
        name: "RegisterComplete",
        meta: {
          authRequired: true,
          title: title + "회원가입 완료",
          breadcrumb: [{ name: "", link: "" }],
        },
        component: () => import("../views/Complete/index.vue"),
      },
      {
        path: "/mypage",
        name: "Mypage",
        meta: {
          authRequired: true,
          title: title + "MY스탬프 팝",
          breadcrumb: [{ name: "", link: "/mypage" }],
        },
        component: () => import("../views/Mypage/Mystamppop/index.vue"),
      },
      {
        path: "/mypage/account",
        name: "MypageAccount",
        meta: {
          authRequired: true,
          title: title + "회원정보 수정",
          breadcrumb: [{ name: "", link: "/mypage/account" }],
        },
        component: () => import("../views/Mypage/Account/index.vue"),
      },
      {
        path: "/mypage/password",
        name: "MypagePassword",
        meta: {
          authRequired: true,
          title: title + "비밀번호 변경",
          breadcrumb: [{ name: "", link: "/mypage/password" }],
        },
        component: () => import("../views/Mypage/Password/index.vue"),
      },
      {
        path: "/mypage/festival/order",
        name: "MypageFestivalOrder",
        meta: {
          authRequired: true,
          title: title + "결제내역/진행현황",
          breadcrumb: [{ name: "", link: "/mypage/festival/order" }],
        },
        component: () => import("../views/Mypage/Festival/Order/index.vue"),
      },
      {
        path: "/mypage/festival/order/:id",
        name: "MypageFestivalOrderDetail",
        meta: {
          authRequired: true,
          title: title + "결제내역/진행현황",
          breadcrumb: [{ name: "", link: "/mypage/festival/order/:id" }],
        },
        component: () =>
          import("../views/Mypage/Festival/OrderDetail/index.vue"),
      },
      {
        path: "festival/mypage/reference",
        name: "MypageFestivalReference",
        meta: {
          authRequired: true,
          title: title + "축제 자료 등록",
          breadcrumb: [{ name: "", link: "festival/mypage/reference" }],
        },
        component: () => import("../views/Mypage/Festival/Reference/index.vue"),
      },
      {
        path: "/festival/order/product",
        name: "FestivalOrderProduct",
        meta: {
          authRequired: true,
          title: title + "상품 선택하기",
          breadcrumb: [{ name: "", link: "/festival/order/product" }],
        },
        component: () => import("../views/Festival/Order/Product/index.vue"),
      },
      {
        path: "festival/order/payment-complete",
        name: "FestivalOrderPaymentComplete",
        meta: {
          authRequired: true,
          title: title + "결제 완료",
          breadcrumb: [{ name: "", link: "festival/order/payment-complete" }],
        },
        component: () =>
          import("../views/Festival/Order/PaymentComplete/index.vue"),
      },
      {
        path: "festival/order/reference",
        name: "FestivalOrderReference",
        meta: {
          authRequired: true,
          title: title + "축제 자료 등록",
          breadcrumb: [{ name: "", link: "festival/order/reference" }],
        },
        component: () => import("../views/Festival/Order/Reference/index.vue"),
      },
      {
        path: "festival/order/complete",
        name: "FestivalOrderComplete",
        meta: {
          authRequired: true,
          title: title + "스탬프 팝 신청완료",
          breadcrumb: [{ name: "", link: "festival/order/complete" }],
        },
        component: () => import("../views/Festival/Order/Complete/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    redirect: "/login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        meta: {
          title: title + "로그인",
          breadcrumb: [{ name: "", link: "" }],
        },
        component: () => import("../views/Login/index.vue"),
      },
      {
        path: "/recover",
        name: "Recover",
        meta: {
          title: title + "비밀번호 찾기",
          breadcrumb: [{ name: "", link: "" }],
        },
        component: () => import("../views/Recover/index.vue"),
      },
      {
        path: "/register",
        name: "Register",
        meta: {
          title: title + "회원가입",
          breadcrumb: [{ name: "", link: "" }],
        },
        component: () => import("../views/Register/index.vue"),
      },
      {
        path: "/entry-cost",
        name: "EntryCost",
        meta: {
          title: title + "입점비용",
          breadcrumb: [{ name: "", link: "/entry-cost" }],
        },
        component: () => import("../views/EntryCost/index.vue"),
      },
      {
        path: "/entry-procedure",
        name: "EntryProcedure",
        meta: {
          title: title + "입점절차",
          breadcrumb: [{ name: "", link: "/entry-procedure" }],
        },
        component: () => import("../views/EntryProcedure/index.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "NotFound",
    meta: {
      title: title + "NotFound",
      breadcrumb: [{ name: "NotFound", link: "*" }],
    },
    component: () => import("../views/NotFound/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["isAuthenticated"];
  if (
    to.matched.some(function (routeInfo) {
      if (
        Object.prototype.hasOwnProperty.call(routeInfo.meta, "authRequired")
      ) {
        return routeInfo.meta.authRequired;
      } else {
        return false;
      }
    })
  ) {
    // 인증이 필요한 페이지일 경우 인증 체크
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    console.log(isAuthenticated);
    if (Object.prototype.hasOwnProperty.call(to.meta, "authRequired")) {
      if (!to.meta.authRequired && isAuthenticated) {
        next("/");
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
