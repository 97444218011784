import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import language from "@/common/language";
import VueProgressBar from "vue-progressbar";

Vue.config.productionTip = false;

// plugins
import AdminDashboard from "./plugins/admin-dashboard";
Vue.use(AdminDashboard);

// moment
import moment from "moment";
moment.locale(language.getLanguage());
Vue.prototype.moment = moment;

// i18n
import i18n from "./i18n";
i18n.locale = language.getLanguage();

// vee-validate
import {
  ValidationProvider,
  extend,
  ValidationObserver,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
for (let rule in rules) {
  extend(rule, rules[rule]);
}
import en from "vee-validate/dist/locale/en.json";
import ko from "vee-validate/dist/locale/ko.json";
localize({
  en,
  ko,
});
localize(language.getLanguage());
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("alpha_dash", {
  message: "특수기호는 (-),(_)만 사용 가능합니다.",
});
extend("min", {
  validate(value, { min }) {
    if (value.length >= min) {
      return true;
    }
    return "{_field_}는 {min}자리 이상이어야 합니다.";
  },
  params: ["min"],
});
extend("max", {
  validate(value, { max }) {
    if (value.length <= max) {
      return true;
    }
    return "{_field_}는 {max}자리 이하여야 합니다.";
  },
  params: ["max"],
});
extend("password", {
  validate: (value) => {
    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{7,16}$/.test(value);
    if (!regex) {
      return "7~16자 영문 대 소문자, 숫자 조합을 사용하세요.";
    } else {
      return true;
    }
  },
});
extend("confirmed", {
  message: "비밀번호가 일치하지 않습니다.",
});
extend("required", {
  message: "{_field_}을(를) 입력해 주세요.",
});
extend("numeric", {
  message: "{_field_}는 숫자만 입력 가능합니다.",
});
extend("email", {
  message: "잘못된 이메일 주소 입니다.",
});
extend("corporation", {
  validate: (value) => {
    const regex = /^[0-9]{0,10}$/.test(value);
    if (!regex) {
      return "사업자등록번호를 올바르게 입력해주세요.";
    } else {
      return true;
    }
  },
});

// vue-progressbar
const options = {
  color: "#386cfa",
  failedColor: "#d62923",
  thickness: "3px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

Vue.use(VueProgressBar, options);

// vue-js-modal
import VModal from "vue-js-modal";
Vue.use(VModal, { dialog: true, dynamic: true, injectModalsContainer: true });

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

// v-calendar
import DatePicker from "v-calendar/lib/components/date-picker.umd";
Vue.component("date-picker", DatePicker);

// vue-dayjs-plugin
import VueDayjs from "vue-dayjs-plugin";
Vue.use(VueDayjs);

// vue-plugin-load-script
import LoadScript from "vue-plugin-load-script";
Vue.config.productionTip = false;
Vue.use(LoadScript);

// css
import "./assets/css/reset.css";
import "./assets/css/style.css";

// scss
import "@/assets/scss/common.scss";

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
