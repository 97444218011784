import BaseButton from "../components/core/BaseButton";
import BaseSideBar from "../components/core/BaseSideBar";
import BaseTable from "../components/core/BaseTable/BaseTable";

const GlobalComponents = {
  install(app) {
    app.component("base-button", BaseButton);
    app.component("base-side-bar", BaseSideBar);
    app.component("base-table", BaseTable);
  },
};

export default GlobalComponents;
