<!-- table_area -->
<template>
  <table width="100%">
    <colgroup>
      <col v-if="isCheckbox" style="width: 100px" />
      <col v-if="useNo" style="width: 100px" />
      <col
        v-for="(item, index) in fields"
        :key="`col_${index}`"
        :style="{ width: item.width ? item.width : '' }"
      />
      <col v-if="isEdit || isDelete" style="width: 80px" />
    </colgroup>
    <thead>
      <tr>
        <th v-if="isCheckbox">{{ checkText }}</th>
        <th v-if="useNo">번호</th>
        <th
          class="table_title"
          v-for="(item, index) in fields"
          :key="`th_${index}`"
        >
          {{ item.label }}
        </th>
        <th v-if="isEdit">{{ editText }}/삭제</th>
        <th v-if="isDelete">삭제</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="loading && items.length == 0">
        <td colspan="100">
          {{ loadingMessage }}
        </td>
      </tr>
      <tr v-else-if="!loading && items.length == 0">
        <td colspan="100">{{ emptyMessage }}</td>
      </tr>
      <tr v-else v-for="(item, index) in items" :key="index">
        <template>
          <td v-if="isCheckbox">
            <input
              type="checkbox"
              :value="item.id"
              :id="item.id"
              v-model="checked"
            />
          </td>
          <td v-if="useNo">
            {{ $n(totalItems - pageSize * (currentPage - 1) - index) }}
          </td>
          <td v-for="(field, index) in fields" :key="`td_${index}`">
            <template v-if="hasValue(item, field)">
              <component
                v-if="field.tag"
                :is="'tag-' + field.tag"
                :item="item"
                :field="field"
                :id="item.id"
                @click="handleClick"
                :useClick="field.useClick"
                :data="itemValue(item, field)"
              ></component>
              <template v-else>
                <a
                  href="#"
                  v-if="field.useClick"
                  @click.prevent.stop="handleClick($event, item, field)"
                >
                  <template v-if="field.useSlot">
                    <slot :name="field.key" :item="item" />
                  </template>
                  <template v-else>
                    {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                  </template>
                </a>
                <template v-else>
                  <template v-if="field.useSlot">
                    <slot :name="field.key" :item="item" />
                  </template>
                  <template v-else>
                    {{ itemValue(item, field) ? itemValue(item, field) : "-" }}
                  </template>
                </template>
              </template>
            </template>
            <template v-else> - </template>
          </td>
          <template v-if="isEdit">
            <td>
              <button type="button" @click="editClick($event, item)">
                <i><font-awesome-icon :icon="['fas', 'edit']" /></i>
              </button>
              <button type="button" @click="deleteClick($event, item)">
                <i><font-awesome-icon :icon="['fas', 'trash-alt']" /></i>
              </button>
            </td>
          </template>
          <template v-if="isDelete">
            <td>
              <button type="button" @click="deleteClick($event, item)">
                <i><font-awesome-icon :icon="['fas', 'trash-alt']" /></i>
              </button>
            </td>
          </template>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import img from "./Img";

export default {
  name: "BaseTable",
  components: {
    "tag-img": img,
  },
  props: {
    useNo: {
      type: Boolean,
      default: false,
      description: "Table loading",
    },
    totalItems: {
      type: Number,
      default: 0,
      description: "",
    },
    currentPage: {
      type: Number,
      default: 0,
      description: "",
    },
    pageSize: {
      type: Number,
      default: 0,
      description: "",
    },
    loadingMessage: {
      type: String,
      default: "조회중..",
      description: "",
    },
    emptyMessage: {
      type: String,
      default: "검색 결과가 없습니다.",
      description: "",
    },
    fields: {
      type: Array,
      default: () => [],
      description: "Table Fields",
    },
    loading: {
      type: Boolean,
      default: true,
      description: "Table loading",
    },
    items: {
      type: Array,
      default: () => [],
      description: "Table Items",
    },
    isEdit: {
      type: Boolean,
      default: false,
      description: "Show edit buttons",
    },
    isDelete: {
      type: Boolean,
      default: false,
      description: "Show only delete button",
    },
    isCheckbox: {
      type: Boolean,
      default: false,
      description: "Show checkbox",
    },
    modelValue: {
      type: String,
      default: "",
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
    defaultChecked: {
      type: Array,
      default: () => [],
      description: "Table checked",
    },
    editText: {
      type: String,
      default: "수정",
    },
    checkText: {
      type: String,
      default: "선택",
    },
  },
  data() {
    return {
      checked: [],
    };
  },
  created() {
    this.checked = this.defaultChecked;
  },
  mounted() {
    /**
     * mounted
     */
  },
  watch: {
    /**
     * watch
     */
    checked(value) {
      this.$emit("onChecked", value);
    },
  },
  computed: {},
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    handleClick(event, item, field) {
      this.$emit("click", {
        event: event,
        item: item,
        field: field,
      });
    },
    editClick(event, item) {
      this.$emit("edit", {
        event: event,
        item: item,
      });
    },
    deleteClick(event, item) {
      this.$emit("delete", {
        event: event,
        item: item,
      });
    },
    hasValue(item, field) {
      /**
       * XXX
       * 로직 개선 필요.
       */
      if (1 < field.key.split(".").length) {
        if (
          Object.prototype.hasOwnProperty.call(item, field.key.split(".")[0])
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              item[field.key.split(".")[0]],
              field.key.split(".")[1]
            )
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(item, field.key)) {
          // console.log(field.key);
          return true;
        } else {
          return false;
        }
      }
    },
    itemValue(item, field) {
      /**
       * XXX
       * 로직 개선 필요.
       */
      let value = "";
      if (1 < field.key.split(".").length) {
        value = item[field.key.split(".")[0]][field.key.split(".")[1]];
      } else {
        value = item[field.key];
      }
      if (field.formatter) {
        if (field.formatter.name == "date") {
          try {
            value = this.$d(new Date(value).getTime(), field.formatter.type);
          } catch (e) {
            console.log(e);
            value = "";
          }
        } else if (field.formatter.name == "number") {
          value = this.$n(value);
        }
      }
      return value;
    },
  },
};
</script>
