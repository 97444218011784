import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "../assets/scss/admin.scss";

export default {
  install(app) {
    app.use(GlobalComponents);
    app.use(GlobalDirectives);
  },
};
