<template>
  <div class="wrapper">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "AuthLayout",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
